<template>
  <div class="demo">
    <div class="enterDetalis_title">入住率情况</div>
    <div class="flex flex-end">
      <div class="flex flex-1 mdName">门店</div>
      <!-- 设备入住率 -->
      <div class="flex flex-column exter_PMS">
        <span class="btn">设备使用率</span>
        <div class="flex enter_btn flex-center">
          <div
            class="asc"
            :class="{'active':condition==='equipmentOccupancyRate' && order ==='asc'}"
            @click="getOccupancyInfo({condition:'equipmentOccupancyRate',order:'asc'})"
          ></div>
          <div
            class="desc"
            :class="{'active':condition==='equipmentOccupancyRate' && order ==='desc'}"
            @click="getOccupancyInfo({condition:'equipmentOccupancyRate',order:'desc'})"
          ></div>
        </div>
      </div>
      <!-- <div class="flex flex-column exter_PMS">
        <span class="btn">PMS入住率</span>
        <div class="flex enter_btn flex-center">
          <div
            class="asc"
            :class="{'active':condition==='pmsOccupancyRate' && order ==='asc'}"
            @click="getOccupancyInfo({condition:'pmsOccupancyRate',order:'asc'})"
          ></div>
          <div
            class="desc"
            :class="{'active':condition==='pmsOccupancyRate' && order ==='desc'}"
            @click="getOccupancyInfo({condition:'pmsOccupancyRate',order:'desc'})"
          ></div>
        </div>
      </div> -->
      <!-- <div class="flex flex-column exter_PMS" v-if="showType==0">
        <span class="btn">入住率对比</span>
        <div class="flex enter_btn flex-center">
          <div
            class="asc"
            :class="{'active':condition==='contrast'&& order ==='asc'  }"
            @click="getOccupancyInfo({condition:'contrast',order:'asc'})"
          ></div>
          <div
            class="desc"
            :class="{'active':condition==='contrast'&& order ==='desc' }"
            @click="getOccupancyInfo({condition:'contrast',order:'desc'})"
          ></div>
        </div>
      </div> -->
    </div>
    <div v-if="occupancyInfo.length" class="enter_panel flex-1" ref="scrollBar">
      <div v-for="(info,index) in occupancyInfo" :key="index" class="flex enter_cell">
        <div class="exter_shop flex-1 text-center">{{showName(info.hotelName)}}</div>
        <div class="exter_facility">{{info.equipmentOccupancyRate}} %</div>
        <!-- <div class="exter_PMS">{{info.pmsOccupancyRate}} %</div>
        <div class="exter_PMS" v-if="showType==0">{{showNum(info.equipmentOccupancyRate,info.pmsOccupancyRate)}} %</div> -->
      </div>
    </div>
    <div v-else class="noData flex flex-center align-center">暂无 入住率 数据</div>
  </div>
</template>
<script>
import { GET_OCCUPANCY_INFO } from "@/api";
import { showName ,floatSub} from "@/api/common";
export default {
  props: {
    showType: {
      type: String,
      default: "0",
    },

  },
  data() {
    return {
      condition: "equipmentOccupancyRate",
      order: "desc",
      // 入住率情况
      occupancyInfo: [],
    };
  },
  created() {
    // if(this.showType==1){
    //     this.condition='equipmentOccupancyRate'
    // }
    this.getOccupancyInfo();
      this.pmcInfoInter = null;
      this.pmcInfoInter = setInterval(() => {
        this.getOccupancyInfo();
      }, 3600000);
  },

  beforeDestroy() {
    clearInterval(this.pmcInfoInter);
  },
  methods: {
    showNum(a,b){
      return floatSub(a,b)
    },
    showName(val) {
      // if(this.$route.meta.title=='万润看板') return val
      // else  
      return showName(val);
    },
    getContrast(val) {
      if (val.condition == this.condition && val.order == this.order) {
        return;
      }
      this.getContrastData(val);
    },
    getContrastData(val={condition: this.condition, order: this.order}) {
      this.condition = val.condition;
      this.order = val.order;
      if (val.order == "asc") {
        this.occupancyInfo.sort((a, b) => {
          return (
            a.equipmentOccupancyRate -
            a.pmsOccupancyRate -
            (b.equipmentOccupancyRate - b.pmsOccupancyRate)
          );
        });
      } else if (val.order == "desc") {
        this.occupancyInfo.sort((a, b) => {
          return (
            b.equipmentOccupancyRate -
            b.pmsOccupancyRate -
            (a.equipmentOccupancyRate - a.pmsOccupancyRate)
          );
        });
      }
      this.interScroll()
    },
    // 获取入住率情况
    async getOccupancyInfo(
      data = { condition: this.condition, order: this.order }
    ) {
      this.condition = data.condition;
      this.order = data.order;
      if (this.condition == "contrast") {
        data.condition = "equipmentOccupancyRate";
      }
      let res = await GET_OCCUPANCY_INFO(data);
      this.occupancyInfo = res.data.data;
      if (this.condition == "contrast") {
        this.getContrastData();
      }
        this.interScroll()
      
    },

  interScroll() {
      let h = -5;
      setTimeout(() => {
        let bar = this.$refs.scrollBar;
        let scrollTop = -1;
        let a = () => {
          this.interScrollBar = setInterval(() => {
            if(bar){
              let top = bar.scrollTop;
              if (top - h > 10 || top - h < -10) {
                h = top;
              } else {
                h++;
              }
              bar.scrollTop = h;
              if (h > bar.scrollTop + 8) {
                if (scrollTop == bar.scrollTop && scrollTop && bar.scrollTop) {
                  this.interScrollBar && clearInterval(this.interScrollBar);
                  h = -5;
                  bar.scrollTop = 0;
                  a();
                  return;
                }
              }
              scrollTop = bar.scrollTop;

            }
          }, 100);
        };
        this.interScrollBar && clearInterval(this.interScrollBar);
        a();
      }, 500);
    },

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/listAll.scss";
// .demo {
//   width: 100%;
//   height: 100%;
// }

</style>